import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { format } from 'd3-format';
import moment from 'moment';
import 'moment/locale/el';
import { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import Fade from 'react-reveal/Fade';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  ChartLabel,
  Hint,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis';
import 'react-vis/dist/style.css';
import useSWR from 'swr';
import getIncomeByType from '../../../src/requests/client/getIncomeByType';
import DotLoader from '../../assets/DotLoader';
import ErrorOnRequest from '../../assets/ErrorOnRequest';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    justifyContent: 'flex-start',
    verticalAlign: 'top',
    width: '100%',
    flexWrap: 'nowrap',
    alignItems: 'baseline',
    alignContent: 'stretch',
  },
  typographyTitle: {
    paddingRight: theme.spacing(2),
  },
  plot: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    minHeight: 350,
  },
  imagePlot: {
    display: 'flex',
    height: '80%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  noResultButton: {
    borderRadius: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    height: '70%',
  },
  hint: {
    border: '1px solid',
    borderRadius: 5,
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
}));

const bodyData = {
  startDate: moment()
    .subtract(1, 'year')
    .utcOffset(0, true)
    .format('YYYY-MM-DD'),
  endDate: moment().utcOffset(0, true).format('YYYY-MM-DD'),
  type: 2,
  perifereia: [],
  transType: [],
  status: [],
  legalForms: [],
};

const BarChartDashboard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(false);
  const [errorOnRequest, setErrorOnRequest] = useState(false);
  const [dataPlot, setDataPlot] = useState([]);
  const [noData, setNoData] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const body = bodyData;

  const { data: data } = useSWR(['/byType', bodyData], () =>
    getIncomeByType('POST', '/gemiCases/byType', null, body)
  );

  useEffect(() => {
    if (data && typeof data !== 'number') {
      let dataFound = true;
      if (data.length <= 0) {
        dataFound = false;
      }
      if (dataFound) {
        setDataPlot(data);
        setNoData(false);
      } else {
        setNoData(true);
      }
      setLoaded(true);
    } else if (typeof data === 'number') {
      setErrorOnRequest(true);
    }
  }, [data]);

  const MouseOver = (v) => {
    setValue(v);
  };

  const myPalette = [
    `${theme.palette.color6.main}`,
    `${theme.palette.color7.main}`,
  ];

  if (errorOnRequest) {
    return <ErrorOnRequest title={props.title} dashboardFlag={true} />;
  }

  if (!data) return <DotLoader title={props.title} dashboardFlag={true} />;

  return (
    <>
      {noData ? (
        <Paper className={classes.paper}>
          <Grid item xs={12} className={classes.title}>
            <Typography
              component='h6'
              variant='h6'
              className={classes.typographyTitle}
            >
              {props.title}
            </Typography>
            <Typography
              variant='body2'
              color='textSecondary'
              className={classes.typographyTitle}
            >
              {props.description}
            </Typography>
          </Grid>
          <div id='lineplot' className={classes.plot}>
            <AutoSizer>
              {({ height, width }) => (
                <>
                  <div
                    style={{
                      width: width,
                      height: height / 4,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      id='filter-button'
                      type='submit'
                      variant='outlined'
                      className={classes.noResultButton}
                    >
                      Δε βρεθηκαν αποτελεσματα
                    </Button>
                  </div>
                  <ContentLoader
                    animate={false}
                    height={(3 * height) / 4}
                    width={width}
                    {...props}
                  >
                    <rect
                      x='0'
                      y={height / (Math.random() * 10 + 1)}
                      rx='0'
                      ry='0'
                      width={width / 10}
                      height={height}
                    />
                    <rect
                      x={width / 5}
                      y={height / (Math.random() * 10 + 1)}
                      rx='0'
                      ry='0'
                      width={width / 10}
                      height={height}
                    />
                    <rect
                      x={(2 * width) / 5}
                      y={height / (Math.random() * 10 + 1)}
                      rx='0'
                      ry='0'
                      width={width / 10}
                      height={height}
                    />
                    <rect
                      x={(3 * width) / 5}
                      y={height / (Math.random() * 10 + 1)}
                      rx='0'
                      ry='0'
                      width={width / 10}
                      height={height}
                    />
                    <rect
                      x={(4 * width) / 5}
                      y={height / (Math.random() * 10 + 1)}
                      rx='0'
                      ry='0'
                      width={width / 10}
                      height={height}
                    />
                  </ContentLoader>
                </>
              )}
            </AutoSizer>
          </div>
        </Paper>
      ) : (
        <Paper className={classes.paper}>
          <Grid item xs={12} className={classes.title}>
            <Typography
              component='h6'
              variant='h6'
              className={classes.typographyTitle}
            >
              {props.title}
            </Typography>
            <Typography
              variant='body2'
              color='textSecondary'
              className={classes.typographyTitle}
            >
              {props.description}
            </Typography>
          </Grid>
          {dataPlot.length > 0 && loaded ? (
            <div className={classes.imagePlot}>
              <Fade>
                <div id='distinctbar' className={classes.plot}>
                  <AutoSizer>
                    {({ height, width }) => (
                      <XYPlot
                        xType='ordinal'
                        width={width}
                        height={(90 * height) / 100}
                        margin={{ left: 45, top: 20 }}
                      >
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        <VerticalBarSeries
                          onValueMouseOver={MouseOver}
                          onSeriesMouseOut={() => setValue(false)}
                          data={dataPlot}
                          style={{ stroke: '#fff' }}
                          color={myPalette[0]}
                        />
                        {value !== false && (
                          <Hint value={value} id='hint'>
                            <div className={classes.hint}>
                              <div>Τύπος: {value.x}</div>
                              <div style={{ display: 'inline-flex' }}>
                                Πλήθος: &nbsp;{' '}
                                <div style={{ color: `${value.color}` }}>
                                  {format(',d')(value.y)}
                                </div>
                              </div>
                            </div>
                          </Hint>
                        )}

                        <XAxis
                          tickTotal={6}
                          tickFormat={function myFormatter(t, i) {
                            return (
                              <foreignObject
                                width={width / (2 * data.length)}
                                height='30'
                              >
                                <div
                                  style={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    marginTop: 10,
                                  }}
                                >
                                  <text
                                    className='rv-xy-plot__axis__tick__text'
                                    style={{ color: '#6b6b76' }}
                                  >
                                    {t}
                                  </text>
                                </div>
                              </foreignObject>
                            );
                          }}
                        />
                        <YAxis
                          tickFormat={(tick) => {
                            let y = Math.round(tick) === tick ? tick : '';
                            if (String(y).length > 3) {
                              return format('.2s')(y);
                            } else {
                              return y;
                            }
                          }}
                        />
                        <ChartLabel
                          text=''
                          includeMargin={false}
                          xPercent={0}
                          yPercent={0.04}
                          style={{
                            transform: 'rotate(0)',
                            textAnchor: 'end',
                          }}
                        />
                      </XYPlot>
                    )}
                  </AutoSizer>
                </div>
              </Fade>
            </div>
          ) : (
            <div id='distinctbar' className={classes.plot}>
              <AutoSizer>
                {({ height, width }) => (
                  <ContentLoader height={height} width={width} {...props}>
                    <rect
                      x='0'
                      y={height / (Math.random() * 10 + 1)}
                      rx='0'
                      ry='0'
                      width={width / 10}
                      height={height}
                    />
                    <rect
                      x={width / 5}
                      y={height / (Math.random() * 10 + 1)}
                      rx='0'
                      ry='0'
                      width={width / 10}
                      height={height}
                    />
                    <rect
                      x={(2 * width) / 5}
                      y={height / (Math.random() * 10 + 1)}
                      rx='0'
                      ry='0'
                      width={width / 10}
                      height={height}
                    />
                    <rect
                      x={(3 * width) / 5}
                      y={height / (Math.random() * 10 + 1)}
                      rx='0'
                      ry='0'
                      width={width / 10}
                      height={height}
                    />
                    <rect
                      x={(4 * width) / 5}
                      y={height / (Math.random() * 10 + 1)}
                      rx='0'
                      ry='0'
                      width={width / 10}
                      height={height}
                    />
                  </ContentLoader>
                )}
              </AutoSizer>
            </div>
          )}
        </Paper>
      )}
    </>
  );
};

export default BarChartDashboard;
