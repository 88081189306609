import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { format } from 'd3-format';
import f from '../../../src/format/format';
import moment from 'moment';
import 'moment/locale/el';
import { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import Fade from 'react-reveal/Fade';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  AreaSeries,
  ChartLabel,
  DiscreteColorLegend,
  Hint,
  HorizontalGridLines,
  LineMarkSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis';
import 'react-vis/dist/style.css';
import useSWR from 'swr';
import { BodyHandling } from '../../../src/context/BodyContext';
import getIncomeByType from '../../../src/requests/client/getIncomeByType';
import DotLoader from '../../assets/DotLoader';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    height: '100%',
  },
  title: {
    justifyContent: 'flex-start',
    display: 'flex',
    verticalAlign: 'top',
    width: '100%',
    flexWrap: 'nowrap',
    alignItems: 'baseline',
    alignContent: 'stretch',
  },
  typographyTitle: {
    paddingRight: theme.spacing(2),
  },
  typographyMonth: {
    fontWeight: 500,
    fontSize: '0.95rem',
    paddingLeft: theme.spacing(2),
  },
  hint: {
    border: '1px solid',
    borderRadius: 5,
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
  imagePlot: {
    display: 'flex',
    height: '80%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  plot: {
    padding: theme.spacing(1),
    height: '100%',
    minHeight: 350,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}));

const EarningsExpensesPlot = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [dataIncomePlot, setDataIncomePlot] = useState([]);
  const [dataExpensesPlot, setDataExpensesPlot] = useState([]);
  const [dataPlot, setDataPlot] = useState([]);
  const [value, setValue] = useState(false);
  const [legends, setLegends] = useState([]);
  const [maxValue, setMaxValue] = useState();

  const nameReqIncome = props.nameReqIncome;
  const nameReqExpenses = props.nameReqExpenses;

  const method = props.method;
  const urlIncome = props.urlIncome;
  const urlExpenses = props.urlExpenses;
  let params = props.params;
  let bodyIncome = props.bodyIncome;
  let bodyExpenses = props.bodyExpenses;

  const { data: dataIncome } = useSWR([nameReqIncome, bodyIncome], () =>
    getIncomeByType(method, urlIncome, params, bodyIncome)
  );

  const { data: dataExpenses } = useSWR([nameReqExpenses, bodyExpenses], () =>
    getIncomeByType(method, urlExpenses, params, bodyExpenses)
  );

  useEffect(() => {
    if (dataIncome && dataIncome !== undefined) {
      setDataIncomePlot(getData(dataIncome, 0));
    }
    if (dataExpenses && dataExpenses !== undefined) {
      setDataExpensesPlot(getData(dataExpenses, 1));
    }
    if (dataIncome && dataExpenses && getData(dataIncome, 0) && getData(dataExpenses, 1)) {
      setDataPlot([getData(dataIncome, 0)[0], getData(dataExpenses, 1)[0]]);
    }
    let legendsArray = [];
    legendsArray.push({
      title: 'Έσοδα',
      color: myPalette[0],
      strokeWidth: 6,
    });
    legendsArray.push({
      title: 'Έξοδα',
      color: myPalette[1],
      strokeWidth: 6,
    });
    setLegends(legendsArray);
  }, [dataIncome, dataExpenses]);

  const MouseOver = (v) => {
    setValue(v);
  };

  const myPalette = [
    `${theme.palette.color6.main}`,
    `${theme.palette.color7.main}`,
  ];

  let arrayOfYValues = [];
  const getData = (data, index) => {
    try {
      var array = JSON.parse(JSON.stringify(data));
      if (array[0].length > 0) {
        array.map((option) => option.map((x) => (x.x = Date.parse(x.x))));
        for (const i in array) {
          for (const j in array[i]) {
            array[i][j].color = myPalette[index];
            arrayOfYValues.push(array[i][j].y);
          }
        }
        setMaxValue(Math.max(...arrayOfYValues));
      }
      return array;
    } catch (err) {
      console.error(err);
    }
  };

  if (!dataIncome && !dataExpenses)
    return <DotLoader title={props.title} dashboardFlag={true} />;

  if (dataIncome && dataExpenses) {
    return (
      <Paper className={classes.paper}>
        <Grid item xs={12} className={classes.title}>
          <Typography
            component="h6"
            variant="h6"
            className={classes.typographyTitle}
          >
            {props.title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.typographyMonth}
          >
            {props.description}
          </Typography>
        </Grid>

        {dataIncomePlot.length > 0 || dataExpensesPlot.length > 0 ? (
          <div className={classes.imagePlot}>
            <Fade>
              <div id="lineplot" className={classes.plot}>
                <AutoSizer>
                  {({ height, width }) => (
                    <>
                      <XYPlot
                        yDomain={maxValue === 0 ? [0, 1] : [0, maxValue]}
                        xType="time"
                        height={(90 * height) / 100}
                        width={width}
                        margin={{ left: 45, top: 20, right: 45 }}
                        style={{ position: 'relative' }}
                      >
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        {dataPlot.map((option, i) => {
                          return (
                            <AreaSeries
                              key={JSON.stringify(option)}
                              data={option}
                              color={fade(myPalette[i], 0.1)}
                            />
                          );
                        })}
                        {dataPlot.map((option, i) => {
                          return (
                            <LineMarkSeries
                              key={JSON.stringify(option)}
                              data={option}
                              color={myPalette[i]}
                              lineStyle={{ pointerEvents: 'none' }}
                              onValueMouseOver={MouseOver}
                              onSeriesMouseOut={() => setValue(false)}
                            />
                          );
                        })}
                        {value !== false && (
                          <Hint value={value} id="hint">
                            <div className={classes.hint}>
                              <div>
                                Ημερομηνία: {moment(value.x).format('MM/YYYY')}
                              </div>

                              <div style={{ display: 'inline-flex' }}>
                                Ποσό: &nbsp;{' '}
                                <div style={{ color: `${value.color}` }}>
                                  {f(value.yString).concat('€')}
                                </div>
                              </div>
                            </div>
                          </Hint>
                        )}
                        <XAxis
                          tickTotal={4}
                          tickFormat={function tickFormat(d) {
                            return moment(d).format('MM/YYYY');
                          }}
                        />
                        <YAxis
                          tickFormat={(tick) => {
                            let y = Math.round(tick) === tick ? tick : '';
                            if (String(y).length > 3) {
                              return format('.2s')(y);
                            } else {
                              return y;
                            }
                          }}
                        />
                        <DiscreteColorLegend
                          height={(15 * height) / 100}
                          width={width}
                          orientation="horizontal"
                          items={legends}
                          style={{ textAlign: 'center', overflow: 'hidden' }}
                        />
                        <ChartLabel
                          text={'Ποσό (€)'}
                          includeMargin={false}
                          xPercent={0}
                          yPercent={0.04}
                          style={{
                            transform: 'rotate(0)',
                            textAnchor: 'end',
                          }}
                        />
                      </XYPlot>
                    </>
                  )}
                </AutoSizer>
              </div>
            </Fade>
          </div>
        ) : (
          <div id="barchart" className={classes.plot}>
            <AutoSizer>
              {({ height, width }) => (
                <ContentLoader
                  animate={false}
                  height={(3 * height) / 4}
                  width={width}
                  {...props}
                >
                  <rect
                    x="0"
                    y={height / (Math.random() * 10 + 1)}
                    rx="0"
                    ry="0"
                    width={width / 10}
                    height={height}
                  />
                  <rect
                    x={width / 5}
                    y={height / (Math.random() * 10 + 1)}
                    rx="0"
                    ry="0"
                    width={width / 10}
                    height={height}
                  />
                  <rect
                    x={(2 * width) / 5}
                    y={height / (Math.random() * 10 + 1)}
                    rx="0"
                    ry="0"
                    width={width / 10}
                    height={height}
                  />
                  <rect
                    x={(3 * width) / 5}
                    y={height / (Math.random() * 10 + 1)}
                    rx="0"
                    ry="0"
                    width={width / 10}
                    height={height}
                  />
                  <rect
                    x={(4 * width) / 5}
                    y={height / (Math.random() * 10 + 1)}
                    rx="0"
                    ry="0"
                    width={width / 10}
                    height={height}
                  />
                </ContentLoader>
              )}
            </AutoSizer>
          </div>
        )}
      </Paper>
    );
  }
  return <DotLoader title={'Έσοδα / Έξοδα'} dashboardFlag={true} />;
};

export default EarningsExpensesPlot;
