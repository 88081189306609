import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import ContentLoader from "react-content-loader";
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  group: {
    justifyContent: "flex-start",
    display: "flex",
    verticalAlign: "top",
    width: "100%",
    flexWrap: "nowrap",
    alignItems: "center",
    alignContent: "stretch",
  },
  typographyTitle: {
    display: "flex",
    flex: 1,
    width: "100%",
  },
  chartContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: 180,
  },
}));

export default function DotLoader(props) {
  const classes = useStyles();

  return (
    <>
      {!props.dashboardFlag ? (
        <Grid item xs={12} className={classes.group}>
          <div style={{ display: "contents" }}>
            <Typography
              component="h6"
              variant="h6"
              colSpan={6}
              className={classes.typographyTitle}
            >
              {props.title}
            </Typography>
          </div>
        </Grid>
      ) : (
        <></>
      )}
      <Paper
        className={classes.paper}
        style={{
          height: props.dashboardFlag
            ? 400
            : props.heightVar
            ? props.heightVar
            : "85%",
          flexDirection: props.dashboardFlag ? "column" : "row",
        }}
      >
        {props.dashboardFlag ? (
          <div style={{ display: "contents" }}>
            <Typography
              component="h6"
              variant="h6"
              colSpan={6}
              className={classes.typographyTitle}
            >
              {props.title}
            </Typography>
          </div>
        ) : (
          <></>
        )}
        <div
          className={classes.chartContainer}
          style={{ alignItems: props.dashboardFlag ? "" : "center" }}
        >
          <AutoSizer id="autosizer">
            {({ height, width }) => (
              <ContentLoader
                height={height}
                width={width}
                backgroundColor="transparent"
              >
                <circle cx={width / 4} cy="10" r="8" />
                <circle cx={(2 * width) / 4} cy="10" r="8" />
                <circle cx={(3 * width) / 4} cy="10" r="8" />
              </ContentLoader>
            )}
          </AutoSizer>
        </div>
      </Paper>
    </>
  );
}
