const MonthsLUT = (props) => {
    const { month } = props;
    let monthVerbal = '';
    switch (month) {
        case '1':
            monthVerbal = 'Ιανουάριο';
            break;
        case '2':
            monthVerbal = 'Φεβρουάριο';
            break;
        case '3':
            monthVerbal = 'Μάρτιο';
            break;
        case '4':
            monthVerbal = 'Απρίλιο';
            break;
        case '5':
            monthVerbal = 'Μάϊο';
            break;
        case '6':
            monthVerbal = 'Ιούνιο';
            break;
        case '7':
            monthVerbal = 'Ιούλιο';
            break;
        case '8':
            monthVerbal = 'Αύγουστο';
            break;
        case '9':
            monthVerbal = 'Σεπτέμβριο';
            break;
        case '10':
            monthVerbal = 'Οκτώβριο';
            break;
        case '11':
            monthVerbal = 'Νοέμβριο';
            break;
        case '12':
            monthVerbal = 'Δεκέμβριο';
            break;
    }
    return monthVerbal;
};

export default MonthsLUT;
