import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles((theme) => ({
  group: {
    justifyContent: "flex-start",
    display: "flex",
    verticalAlign: "top",
    width: "100%",
    flexWrap: "nowrap",
    alignItems: "center",
    alignContent: "stretch",
  },
  noResultButton: {
    borderRadius: 0,
    cursor: "auto",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    height: "70%",
  },
  errorButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  typographyTitle: {
    display: "flex",
    flex: 1,
  },
  paperOnError: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  chartContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: 180,
  },
}));

export default function ErrorOnRequest(props) {
  const classes = useStyles();

  return (
    <>
      {!props.dashboardFlag ? (
        <Grid item xs={12} className={classes.group}>
          <div style={{ display: "contents" }}>
            <Typography
              component="h6"
              variant="h6"
              colSpan={6}
              className={classes.typographyTitle}
            >
              {props.title}
            </Typography>
          </div>
        </Grid>
      ) : (
        <></>
      )}
      <Paper className={classes.paperOnError}>
        {props.dashboardFlag ? (
          <Grid item xs={12} className={classes.group}>
            <div style={{ display: "contents" }}>
              <Typography
                component="h6"
                variant="h6"
                colSpan={6}
                className={classes.typographyTitle}
              >
                {props.title}
              </Typography>
            </div>
          </Grid>
        ) : (
          <></>
        )}
        <div className={classes.chartContainer}>
          <AutoSizer id="autosizer">
            {({ height, width }) => (
              <img
                src="/error.svg"
                id="image"
                xs={12}
                md={12}
                lg={12}
                width={width}
                height={height}
              />
            )}
          </AutoSizer>
        </div>
        <Button
          id="filter-button"
          variant="outlined"
          className={clsx(classes.noResultButton, classes.errorButton)}
          style={{ cursor: "auto" }}
        >
          Κατι πηγε λαθος
        </Button>
      </Paper>
    </>
  );
}
