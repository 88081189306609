import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import f from '../../../src/format/format';
import moment from 'moment';
import 'moment/locale/el';
import { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import Slide from 'react-reveal/Slide';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Hint, RadialChart } from 'react-vis';
import useSWR from 'swr';
import getIncomeByType from '../../../src/requests/client/getIncomeByType';
import DotLoader from '../../assets/DotLoader';
import ErrorOnRequest from '../../assets/ErrorOnRequest';
import MonthsLUT from '../../assets/MonthsLUT';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    justifyContent: 'flex-start',
    display: 'flex',
    verticalAlign: 'top',
    width: '100%',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    alignContent: 'stretch',
  },
  typographyTitle: {
    display: 'flex',
    flex: 1,
  },
  typographyEarningsNumber: {
    fontWeight: 500,
    fontSize: '2.8rem',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontWeight: 500,
      fontSize: '1.9rem',
    },
  },
  typographyMonth: {
    alignSelf: 'flex-end',
    fontWeight: 500,
    fontSize: '1rem',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  chartContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minHeight: 180,
  },
  chartContainerNoData: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minHeight: 240,
  },
  radialChartDescription: {
    // display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  boxDescription: {
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  noResultButton: {
    borderRadius: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    height: '70%',
  },
  descriptionTitle: {
    marginLeft: theme.spacing(1),
    fontWeight: 450,
    fontSize: '0.9rem',
  },
  descriptionAmount: {
    fontWeight: 450,
    fontSize: '0.9rem',
  },
  detailsDescription: {
    marginLeft: theme.spacing(1),
  },
  hint: {
    border: '1px solid',
    borderRadius: 5,
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
}));

const bodyData = {
  startDate: moment().utcOffset(0, true).startOf('month'),
  endDate: moment().utcOffset(0, true),
  type: 1,
  states: [],
  legalForms: [],
  departments: [],
  member: '2',
  block: [],
};

const EarningsWithoutComparison = () => {
  const classes = useStyles();
  const theme = useTheme();
  moment.locale('el');
  let month = moment().format('M');
  let year = moment().format('YYYY');
  const [sum, setSum] = useState(0);
  const [noCurData, setNoCurData] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [finalData, setFinalData] = useState(undefined);
  const [errorOnRequest, setErrorOnRequest] = useState(false);
  const [colorsIndex, setColorsIndex] = useState([]);
  const [value, setValue] = useState(false);

  const body = bodyData;

  const { data: data } = useSWR(['/byType', bodyData], () =>
    getIncomeByType('POST', '/income/byType', null, body)
  );

  const MouseOver = (v) => {
    setValue(v);
  };

  const myPalette = [
    `${theme.palette.color1.main}`,
    `${theme.palette.color2.main}`,
    `${theme.palette.color3.main}`,
    `${theme.palette.color4.main}`,
    `${theme.palette.color5.main}`,
    `${theme.palette.color6.main}`,
    `${theme.palette.color7.main}`,
    `${theme.palette.color8.main}`,
  ];

  useEffect(() => {
    if (data && typeof data !== 'number') {
      setErrorOnRequest(false);
      const dataArray = JSON.parse(JSON.stringify(data));
      let varSum = 0;
      let colorsArray = [];
      if (!dataArray.length > 0) {
        setNoCurData(true);
      }
      for (const i in dataArray) {
        varSum += dataArray[i].y;
        dataArray[i]['color'] = i;
        colorsArray.push(i);
      }
      setSum(varSum);
      setFinalData(dataArray);
      setColorsIndex(colorsArray);
      setLoaded(true);
    }
    if (typeof data === 'number') {
      setErrorOnRequest(true);
    }
  }, [data]);

  if (errorOnRequest) {
    return (
      <ErrorOnRequest title={'Εισπράξεις Επιμελητηρίου'} dashboardFlag={true} />
    );
  }

  if (!data && !loaded && !finalData) {
    return (
      <DotLoader title={'Εισπράξεις Επιμελητηρίου'} dashboardFlag={true} />
    );
  }

  if (finalData && loaded) {
    return (
      <>
        <Paper className={classes.paper}>
          <Grid item xs={12} className={classes.title}>
            <Typography
              component="h6"
              variant="h6"
              className={classes.typographyTitle}
            >
              Εισπράξεις Επιμελητηρίου
            </Typography>
          </Grid>
          <div
            style={{
              display: 'inline-flex',
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            <Typography
              component="h6"
              variant="h6"
              className={classes.typographyEarningsNumber}
            >
              {f(sum).concat('€')}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.typographyMonth}
            >
              τον <MonthsLUT month={month} /> {year}
            </Typography>
          </div>
          {finalData.length > 0 ? (
            <>
              <div className={classes.chartContainer}>
                <AutoSizer id="autosizer">
                  {({ height, width }) => (
                    <>
                      {noCurData ? (
                        <ContentLoader
                          title="Δε βρέθηκαν αποτελέσματα"
                          animate={false}
                          height={height}
                          width={width}
                          backgroundColor="transparent"
                          gradientRatio={0}
                        >
                          <circle
                            cx={width / 2}
                            cy={height / 2}
                            r={height / 2}
                          />
                        </ContentLoader>
                      ) : (
                        <div id="ref">
                          <Slide top>
                            <RadialChart
                              onValueMouseOver={MouseOver}
                              onSeriesMouseOut={() => setValue(false)}
                              colorType="category"
                              colorDomain={colorsIndex}
                              colorRange={myPalette}
                              getAngle={(d) => d.y}
                              data={finalData}
                              radius={(1 * height) / 2}
                              width={width}
                              height={height}
                              style={{ stroke: '#fff', strokeWidth: '2' }}
                            >
                              {value !== false && (
                                <Hint value={value} id="hint">
                                  <div className={classes.hint}>
                                    <div>
                                      {finalData.map((results) =>
                                        value.id == results.id ? results.x : ''
                                      )}
                                    </div>
                                    <div style={{ display: 'inline-flex' }}>
                                      <div
                                        style={{
                                          color: myPalette[value.color],
                                        }}
                                      >
                                        {f(value.yString).concat('€')}
                                      </div>
                                    </div>
                                  </div>
                                </Hint>
                              )}
                            </RadialChart>
                          </Slide>
                        </div>
                      )}
                    </>
                  )}
                </AutoSizer>
              </div>
              {/* <div style={{ display: 'flex' }}> */}
                <div
                  id="radial-chart-description"
                  className={classes.radialChartDescription}
                >
                  <>
                    {finalData.map(function (option, i) {
                      return (
                        <Box
                          display="flex"
                          flexWrap="nowrap"
                          bgcolor="background.paper"
                          className={classes.boxDescription}
                          key={option.x}
                        >
                          <Box p={1} bgcolor={myPalette[i]}></Box>
                          <div className={classes.detailsDescription}>
                            <Typography className={classes.descriptionTitle}>
                              {option.x}
                            </Typography>
                            {option.yString ? (
                              <div className={classes.detailsDescription}>
                                <div style={{ display: 'inline-flex' }}>
                                  <Typography
                                    color="textSecondary"
                                    className={classes.descriptionAmount}
                                  >
                                    {f(option.yString).concat('€')}
                                  </Typography>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Box>
                      );
                    })}
                  </>
                </div>
              {/* </div> */}
            </>
          ) : (
            <div className={classes.chartContainerNoData}>
              <AutoSizer>
                {({ height, width }) => (
                  <>
                    <ContentLoader
                      animate={false}
                      height={(2 * height) / 3}
                      width={width}
                      backgroundColor="transparent"
                      gradientRatio={0}
                    >
                      <circle cx={width / 2} cy={height / 3} r={height / 3} />
                      <rect
                        x={(1 * width) / 4}
                        y={(3 * height) / 4}
                        rx="0"
                        ry="0"
                        width={(2 * width) / 4}
                        height="10"
                      />
                    </ContentLoader>
                    <div
                      style={{
                        width: width,
                        height: height / 3,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        id="filter-button"
                        variant="outlined"
                        className={classes.noResultButton}
                        style={{ cursor: 'auto' }}
                      >
                        Δε βρεθηκαν αποτελεσματα
                      </Button>
                    </div>
                  </>
                )}
              </AutoSizer>
            </div>
          )}
        </Paper>
      </>
    );
  }

  return <DotLoader title={'Εισπράξεις Επιμελητηρίου'} dashboardFlag={true} />;
};

export default EarningsWithoutComparison;
