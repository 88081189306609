import { Container, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  gridContainer: {
    margin: theme.spacing(1),
    width: "100%",
  },
  title: {
    justifyContent: "center",
    display: "flex",
    verticalAlign: "top",
    width: "100%",
    flexWrap: "nowrap",
    alignItems: "stretch",
    alignContent: "stretch",
  },
  typographyTitle: {
    display: "flex",
  },
  noAccessContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: 180,
  },
}));

export default function NoPermissionPage() {
  const classes = useStyles();

  return (
    <Container
      direction="column"
      id="container"
      maxWidth="xl"
      className={classes.container}
    >
      <Grid item className={classes.gridContainer}>
        <Grid item xs={12} className={classes.title}>
          <Typography
            component="h5"
            variant="h5"
            colSpan={6}
            className={classes.typographyTitle}
          >
            Δεν έχετε πρόσβαση στην συγκεκριμένη επιλογή.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.title}>
          <Typography
            component="h6"
            variant="h6"
            colSpan={6}
            className={classes.typographyTitle}
          >
            Παρακαλώ επικοινωνήστε με τον διαχειριστή του συστήματος.
          </Typography>
        </Grid>
        <div className={classes.noAccessContainer}>
        <AutoSizer id="autosizer">
            {({ height, width }) => (
              <img
                src="/access-denied.svg"
                id="image"
                xs={12}
                md={12}
                lg={12}
                width={width}
                height={height}
              />
            )}
          </AutoSizer>
          </div>
      </Grid>
    </Container>
  );
}
