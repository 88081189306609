import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import "bootstrap/dist/css/bootstrap.min.css";
import clsx from "clsx";
import moment from "moment";
import "moment/locale/el";
import { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import Fade from "react-reveal/Fade";
import useSWR from "swr";
import getIncomeByType from "../../../src/requests/client/getIncomeByType";
import DotLoader from "../../assets/DotLoader";
import ErrorOnRequest from "../../assets/ErrorOnRequest";
import MonthsLUT from "../../assets/MonthsLUT";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
  },
  title: {
    justifyContent: "flex-start",
    display: "flex",
    verticalAlign: "top",
    width: "100%",
    flexWrap: "nowrap",
    alignItems: "stretch",
    alignContent: "stretch",
  },
  typographyTitle: {
    display: "flex",
    alignSelf: "flex-start",
    flex: 1,
  },
  descr: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  typographyEarningsNumber: {
    fontWeight: 500,
    fontSize: "2.8rem",
    paddingRight: theme.spacing(2),
  },
  typographyMonth: {
    alignSelf: "flex-end",
    fontWeight: 500,
    fontSize: "1rem",
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  boxDescription: {
    alignItems: "center",
    marginTop: theme.spacing(1),
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: fade("#000000", 0.2),
    borderRadius: 10,
    width: "100%",
  },
  subscriptionDetails: {
    padding: theme.spacing(1),
    width: "100%",
  },
  subscriptionTitle: {
    fontWeight: 400,
    fontSize: "1rem",
    display: "flex",
    alignSelf: "flex-start",
    flex: 1,
  },
  subscriptionPercentageNumber: {
    fontWeight: 450,
    fontSize: "1.1rem",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: "8%",
    display: "flex",
    justifyContent: "flex-end",
  },
  deletionPercentageNumber: {
    fontWeight: 450,
    fontSize: "1.1rem",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: "8%",
    display: "flex",
    justifyContent: "flex-end",
  },
  subscriptionColor: {
    color: theme.palette.success.main,
  },
  deletionColor: {
    color: theme.palette.error.main,
  },
}));

const bodyData = {
  startDate: moment().utcOffset(0, true).startOf("month"),
  endDate: moment().utcOffset(0, true),
  inactiveStatus: process.env.NEXT_PUBLIC_CHAMBER_I18N_ID.split(", ")
};

const paramsData = { lang: process.env.NEXT_PUBLIC_LANG };

const NewSubscriptions = () => {
  const classes = useStyles();
  const [finalData, setFinalData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [errorOnRequest, setErrorOnRequest] = useState(false);

  moment.locale("el");
  let month = moment().format("M");
  let year = moment().format("YYYY");

  const body = bodyData;
  const params = paramsData;

  const { data: data } = useSWR(["/byType", bodyData], () =>
    getIncomeByType("POST", "/establishmentsGrouped/limit", params, body)
  );

  useEffect(() => {
    if (data && typeof data !== "number") {
      const dataArray = JSON.parse(JSON.stringify(data));
      let varSumReg = 0;
      let varSumDel = 0;
      for (const i in dataArray.data) {
        varSumReg += dataArray.data[i].yRegistrations;
        varSumDel += dataArray.data[i].yDeletions;
      }
      for (const i in dataArray.data) {
        dataArray.data[i].percentageReg =
          (dataArray.data[i].yRegistrations / varSumReg) * 100;
        dataArray.data[i].percentageDel =
          (dataArray.data[i].yDeletions / varSumDel) * 100;
      }
      setFinalData(dataArray);
      setLoaded(true);
    }
    if (typeof data === "number") {
      setErrorOnRequest(true);
    }
  }, [data]);

  if (errorOnRequest) {
    return (
      <ErrorOnRequest title={"Εγγραφές / Διαγραφές"} dashboardFlag={true} />
    );
  }

  if (!data)
    return <DotLoader title={"Εγγραφές / Διαγραφές"} dashboardFlag={true} />;

  if (finalData && finalData.data && loaded) {
    return (
      <>
        <Paper className={classes.paper}>
          <Grid item xs={12} className={classes.title}>
            <Typography
              component="h6"
              variant="h6"
              className={classes.typographyTitle}
            >
              Εγγραφές / Διαγραφές
            </Typography>
          </Grid>
          <div className={classes.descr}>
            <div style={{ display: "inline-flex" }}>
              <Typography
                component="h6"
                variant="h6"
                className={clsx(
                  classes.typographyEarningsNumber,
                  classes.subscriptionColor
                )}
              >
                {finalData.countRegistrations}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.typographyMonth}
              >
                εγγραφές τον <MonthsLUT month={month} /> {year}
              </Typography>
            </div>
            <div style={{ display: "inline-flex" }}>
              <Typography
                component="h6"
                variant="h6"
                className={clsx(
                  classes.typographyEarningsNumber,
                  classes.deletionColor
                )}
              >
                {finalData.countDeletions}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.typographyMonth}
              >
                διαγραφές τον <MonthsLUT month={month} /> {year}
              </Typography>
            </div>
          </div>
          {finalData.data.length > 0 ? (
            <Box
              display="flex"
              flexWrap="nowrap"
              bgcolor="background.paper"
              className={classes.boxDescription}
              id="box-wrapper"
            >
              <div style={{ width: "100%" }}>
                <Fade>
                  <Box id="details=box" className={classes.subscriptionDetails}>
                    <>
                      {finalData.data.map((option) => (
                        <Box
                          style={{ width: "100%", overflow: "visible" }}
                          key={option.id}
                        >
                          <div
                            style={{ display: "inline-flex", width: "100%" }}
                          >
                            <>
                              <Typography className={classes.subscriptionTitle}>
                                {option.x}
                              </Typography>
                            </>
                          </div>
                          <div
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <>
                              <ProgressBar
                                variant="success"
                                now={option.percentageReg}
                                style={{ display: "flex", flex: 1 }}
                              />
                              <Typography
                                className={clsx(
                                  classes.subscriptionPercentageNumber,
                                  classes.subscriptionColor
                                )}
                              >
                                {option.yRegistrations}
                              </Typography>
                            </>
                          </div>
                          <div
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <>
                              <ProgressBar
                                variant="danger"
                                now={option.percentageDel}
                                style={{ display: "flex", flex: 1 }}
                              />
                              <Typography
                                className={clsx(
                                  classes.deletionPercentageNumber,
                                  classes.deletionColor
                                )}
                              >
                                {option.yDeletions}
                              </Typography>
                            </>
                          </div>
                        </Box>
                      ))}
                    </>
                  </Box>
                </Fade>
              </div>
            </Box>
          ) : (
            <></>
          )}
        </Paper>
      </>
    );
  }

  return <DotLoader title={"Εγγραφές / Διαγραφές"} dashboardFlag={true} />;
};

export default NewSubscriptions;
