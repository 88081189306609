import { formatLocale } from "d3-format";


var locale = formatLocale({
  decimal: ",",
  thousands: ".",
  grouping: [3],
  currency: ["", "\u00a0€"],
});

var f = locale.format(",.2f");

export default f; 
