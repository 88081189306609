import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import clsx from 'clsx';
import { format } from 'd3-format';
import f from '../../../src/format/format';
import moment from 'moment';
import 'moment/locale/el';
import { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Hint, RadialChart } from 'react-vis';
import 'react-vis/dist/style.css';
import useSWR from 'swr';
import getIncomeByType from '../../../src/requests/client/getIncomeByType';
import DotLoader from '../../assets/DotLoader';
import ErrorOnRequest from '../../assets/ErrorOnRequest';
import MonthsLUT from '../../assets/MonthsLUT';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    // [theme.breakpoints.down("sm")]: {
    //   paddingLeft: 0,
    //   paddingRight: 0,
    //   paddingTop: theme.spacing(1),
    //   paddingBottom: theme.spacing(1),
    // },
  },
  chartContainerNoData: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minHeight: 240,
  },
  title: {
    justifyContent: 'flex-start',
    verticalAlign: 'top',
    width: '100%',
    flexWrap: 'nowrap',
    alignItems: 'baseline',
    alignContent: 'stretch',
  },
  typographyTitle: {
    paddingRight: theme.spacing(2),
  },
  typographyMonth: {
    alignSelf: 'flex-end',
    fontWeight: 500,
    fontSize: '1rem',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  typographyEarningsNumber: {
    fontWeight: 500,
    fontSize: '2.8rem',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontWeight: 500,
      fontSize: '1.9rem',
    },
  },
  typographyPercentage: {
    alignSelf: 'flex-end',
    fontWeight: 500,
    fontSize: '1.4rem',
    paddingLeft: theme.spacing(1),
  },
  lastMonth: {
    alignSelf: 'flex-end',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  plot: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 180,
  },
  boxDescription: {
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  descriptionTitle: {
    marginLeft: theme.spacing(1),
    fontWeight: 450,
    fontSize: '0.9rem',
  },
  descriptionAmount: {
    fontWeight: 450,
    fontSize: '0.9rem',
  },
  detailsDescription: {
    marginLeft: theme.spacing(1),
  },
  radialChartDescription: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  noResultButton: {
    borderRadius: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    height: '70%',
  },
  rise: {
    color: theme.palette.success.main,
  },
  decrease: {
    color: theme.palette.error.main,
  },
  hint: {
    border: '1px solid',
    borderRadius: 5,
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
}));

const bodyData = {
  startDate: moment().utcOffset(0, true).startOf('month').format('YYYY-MM-DD'),
  endDate: moment().utcOffset(0, true).format('YYYY-MM-DD'),
  states: [],
  legalForms: [],
  departments: [],
  incomeType: [],
  type: 1,
};

const bodyCompareData = {
  startDate: moment()
    .subtract(1, 'year')
    .utcOffset(0, true)
    .startOf('month')
    .format('YYYY-MM-DD'),
  endDate: moment().subtract(1, 'year').utcOffset(0, true).format('YYYY-MM-DD'),
  states: [],
  legalForms: [],
  departments: [],
  incomeType: [],
  type: 1,
};

const IncomeGemi = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [finalData, setFinalData] = useState([]);
  const [sum, setSum] = useState(0);
  let month = moment().format('M');
  let year = moment().format('YYYY');
  let lastYear = moment().subtract(1, 'year').format('YYYY');
  const [sumCompare, setSumCompare] = useState(0);
  const [noCurData, setNoCurData] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [errorOnRequest, setErrorOnRequest] = useState(false);
  const [difPercentage, setDifPercentage] = useState();
  const [colorsIndex, setColorsIndex] = useState([]);
  const [value, setValue] = useState(false);

  const body = bodyData;
  const bodyCompare = bodyCompareData;

  const { data: data } = useSWR(['/byType', bodyData], () =>
    getIncomeByType('POST', '/gemiIncome/byType', null, body)
  );

  const { data: dataCompare } = useSWR(['/byType', bodyCompareData], () =>
    getIncomeByType('POST', '/gemiIncome/byType', null, bodyCompare)
  );

  useEffect(() => {
    if (data && typeof data !== 'number') {
      if (dataCompare && typeof dataCompare !== 'number') {
        setErrorOnRequest(false);
        const dataArrayCompare = JSON.parse(JSON.stringify(dataCompare));
        const dataArray = JSON.parse(JSON.stringify(data));
        let colorsArray = [];
        let varSum = 0;
        if (!dataArray.length > 0) {
          setNoCurData(true);
        }
        for (const i in dataArray) {
          varSum += dataArray[i].y;
          dataArray[i]['color'] = i;
          colorsArray.push(i);
          for (const j in dataArrayCompare) {
            if (dataArrayCompare[j].type_id === dataArray[i].type_id) {
              dataArray[i]['difPercentage'] =
                dataArray[i].y - dataArrayCompare[j].y > 0
                  ? (
                      ((dataArray[i].y - dataArrayCompare[j].y) /
                        dataArrayCompare[j].y) *
                      100
                    ).toFixed(2)
                  : (
                      ((dataArrayCompare[j].y - dataArray[i].y) /
                        dataArrayCompare[j].y) *
                      100
                    ).toFixed(2);
              dataArray[i]['rise'] =
                dataArrayCompare[j].y === 0
                  ? null
                  : dataArray[i].y - dataArrayCompare[j].y > 0
                  ? true
                  : false;
            }
          }
        }
        for (const j in dataArrayCompare) {
          let found = false;
          for (const i in dataArray) {
            if (dataArrayCompare[j].x === dataArray[i].x) {
              found = true;
            }
          }
          if (!found) {
            let objectToPush = {};
            objectToPush.x = dataArrayCompare[j].x;
            objectToPush.difPercentage = (100).toFixed(2);
            objectToPush.rise = false;
            dataArray.push(objectToPush);
          }
        }
        let varSumCompare = 0;
        for (const i in dataArrayCompare) {
          varSumCompare += dataArrayCompare[i].y;
        }
        setSum(varSum);
        setSumCompare(varSumCompare);
        setColorsIndex(colorsArray);
        setDifPercentage(
          varSumCompare > 0
            ? varSum - varSumCompare > 0
              ? (((varSum - varSumCompare) / varSumCompare) * 100).toFixed(2)
              : (((varSumCompare - varSum) / varSumCompare) * 100).toFixed(2)
            : ''
        );
        setFinalData(dataArray);
        setLoaded(true);
      }
    }
    if (typeof data === 'number' || typeof dataCompare === 'number') {
      setErrorOnRequest(true);
    }
  }, [data, dataCompare]);

  const MouseOver = (v) => {
    setValue(v);
  };

  const myPalette = [
    `${theme.palette.color1.main}`,
    `${theme.palette.color2.main}`,
    `${theme.palette.color3.main}`,
    `${theme.palette.color4.main}`,
    `${theme.palette.color5.main}`,
  ];

  if (errorOnRequest) {
    return (
      <ErrorOnRequest
        title={'Ποσό Επιμελητηρίου Εισπράξεων Γ.Ε.Μη'}
        dashboardFlag={true}
      />
    );
  }

  if (!data && !dataCompare && !loaded && !finalData)
    return (
      <DotLoader
        title={'Ποσό Επιμελητηρίου Εισπράξεων Γ.Ε.Μη'}
        dashboardFlag={true}
      />
    );

  if (finalData && loaded) {
    return (
      <Paper className={classes.paper}>
        <Grid item xs={12} className={classes.title}>
          <Typography
            component='h6'
            variant='h6'
            className={classes.typographyTitle}
          >
            Ποσό Επιμελητηρίου Εισπράξεων Γ.Ε.Μη
          </Typography>
        </Grid>
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <Typography
            component='h6'
            variant='h6'
            className={classes.typographyEarningsNumber}
          >
            {f(sum).concat('€')}
          </Typography>
          <Typography
            variant='body2'
            color='textSecondary'
            className={classes.typographyMonth}
          >
            τον <MonthsLUT month={month} /> {year}
          </Typography>
        </div>
        {finalData.length > 0 ? (
          <>
            <div
              style={{
                display: 'inline-flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {sum - sumCompare > 0 ? (
                <>
                  <ArrowUpwardIcon fontSize='large' className={classes.rise} />
                  <Typography
                    variant='body2'
                    color='secondary'
                    className={clsx(classes.typographyPercentage, classes.rise)}
                  >
                    {f(difPercentage).concat('%')}
                  </Typography>
                </>
              ) : (
                <>
                  <ArrowDownwardIcon
                    fontSize='large'
                    className={classes.decrease}
                  />
                  <Typography
                    variant='body2'
                    color='secondary'
                    className={clsx(
                      classes.typographyPercentage,
                      classes.decrease
                    )}
                  >
                    {f(difPercentage).concat('%')}
                  </Typography>
                </>
              )}
              <Typography
                variant='body2'
                color='textSecondary'
                className={classes.lastMonth}
              >
                από τον <MonthsLUT month={month} /> {lastYear}
              </Typography>
            </div>
            <Fade>
              <div id='code-chart' className={classes.plot}>
                <AutoSizer>
                  {({ height, width }) => (
                    <>
                      {noCurData ? (
                        <ContentLoader
                          title='Δε βρέθηκαν αποτελέσματα'
                          animate={false}
                          height={height}
                          width={width}
                          backgroundColor='transparent'
                          gradientRatio={0}
                        >
                          <circle
                            cx={width / 2}
                            cy={height / 2}
                            r={height / 2}
                          />
                        </ContentLoader>
                      ) : (
                        <Slide bottom>
                          <RadialChart
                            onValueMouseOver={MouseOver}
                            onSeriesMouseOut={() => setValue(false)}
                            colorType='category'
                            colorDomain={colorsIndex}
                            colorRange={myPalette}
                            getAngle={(d) => d.y}
                            data={finalData}
                            radius={(1 * height) / 2}
                            width={width}
                            height={height}
                            style={{ stroke: '#fff', strokeWidth: '2' }}
                          >
                            {value !== false && (
                              <Hint value={value} id='hint'>
                                <div className={classes.hint}>
                                  <div>
                                    {finalData.map((results) =>
                                      value.id == results.id ? results.x : ''
                                    )}
                                  </div>
                                  <div style={{ display: 'inline-flex' }}>
                                    <div
                                      style={{
                                        color: myPalette[value.color],
                                      }}
                                    >
                                      {f(value.yString).concat('€')}
                                    </div>
                                  </div>
                                </div>
                              </Hint>
                            )}
                          </RadialChart>
                        </Slide>
                      )}
                    </>
                  )}
                </AutoSizer>
              </div>
              <div
                id='radial-chart-description'
                className={classes.radialChartDescription}
              >
                {finalData.map(function (option, i) {
                  return (
                    <Box
                      display='flex'
                      flexWrap='nowrap'
                      bgcolor='background.paper'
                      className={classes.boxDescription}
                      key={option.x}
                    >
                      <Box p={1} bgcolor={myPalette[i]}></Box>
                      <div className={classes.detailsDescription}>
                        <Typography className={classes.descriptionTitle}>
                          {option.x}
                        </Typography>
                        {option.yString ? (
                          <div className={classes.detailsDescription}>
                            <div style={{ display: 'inline-flex' }}>
                              <Typography
                                color='textSecondary'
                                className={classes.descriptionAmount}
                              >
                                {f(option.yString).concat('€')}
                              </Typography>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {option.rise ? (
                          <div style={{ display: 'inline-flex' }}>
                            <ArrowUpwardIcon
                              fontSize='small'
                              className={classes.rise}
                            />
                            <Typography
                              color='textSecondary'
                              className={clsx(
                                classes.descriptionTitle,
                                classes.rise
                              )}
                            >
                              {f(option.difPercentage).concat('%')}
                            </Typography>
                          </div>
                        ) : option.rise === false ? (
                          <div style={{ display: 'inline-flex' }}>
                            <ArrowDownwardIcon
                              fontSize='small'
                              className={classes.decrease}
                            />
                            <Typography
                              color='textSecondary'
                              className={clsx(
                                classes.descriptionTitle,
                                classes.decrease
                              )}
                            >
                              {f(option.difPercentage).concat('%')}
                            </Typography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Box>
                  );
                })}
              </div>
            </Fade>
          </>
        ) : (
          <div className={classes.chartContainerNoData}>
            <AutoSizer>
              {({ height, width }) => (
                <>
                  <ContentLoader
                    animate={false}
                    height={(2 * height) / 3}
                    width={width}
                    backgroundColor='transparent'
                    gradientRatio={0}
                  >
                    <circle cx={width / 2} cy={height / 3} r={height / 3} />
                    <rect
                      x={(1 * width) / 4}
                      y={(3 * height) / 4}
                      rx='0'
                      ry='0'
                      width={(2 * width) / 4}
                      height='10'
                    />
                  </ContentLoader>
                  <div
                    style={{
                      width: width,
                      height: height / 3,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      id='filter-button'
                      variant='outlined'
                      className={classes.noResultButton}
                      style={{ cursor: 'auto' }}
                    >
                      Δε βρεθηκαν αποτελεσματα
                    </Button>
                  </div>
                </>
              )}
            </AutoSizer>
          </div>
        )}
      </Paper>
    );
  }

  return (
    <DotLoader
      title={'Ποσό Επιμελητηρίου Εισπράξεων Γ.Ε.Μη'}
      dashboardFlag={true}
    />
  );
};

export default IncomeGemi;
