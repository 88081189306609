import AppBar from '@material-ui/core/AppBar';
import Box from '@mui/material/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import {
  getUserPermissions,
  getUserType,
} from '../../../public/authentication/authentication';
import { BodyHandling } from '../../../src/context/BodyContext';
import UserContext from '../../../src/UserContext';
import NoPermissionPage from '../../assets/NoPermissionPage';
import BarChartDashboard from '../../charts/dashboard/BarChartDashboard';
import Earnings from '../../charts/dashboard/Earnings';
import EarningsWithoutComparison from '../../charts/dashboard/EarningsWithoutComparison';
import ExpensesWithoutComparison from '../../charts/dashboard/ExpensesWithoutComparison';
import ExpensesBarChart from '../../charts/dashboard/ExpensesBarChart';
import IncomeGemi from '../../charts/dashboard/IncomeGemi';
import IncomePlot from '../../charts/dashboard/IncomePlot';
import NewSubscriptions from '../../charts/dashboard/NewSubscriptions';
import EarningsExpensesPlot from '../../charts/dashboard/EarningsExpensesPlot';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const tabsTheme = createTheme({
  palette: {
    secondary: {
      main: '#212121',
      light: '#7f7f7f',
      dark: '#90AE0D',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  boxWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  boxWrapper2: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  gridContainer1: {
    width: '60%',
    padding: 0,
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  gridContainer2: {
    width: '40%',
    padding: 0,
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  gridContainer3: {
    width: '40%',
    padding: 0,
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  gridContainer4: {
    width: '60%',
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  gridContainer5: {
    width: '40%',
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  gridContainer6: {
    width: '60%',
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  gridContainer7: {
    width: '50%',
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  gridContainer8: {
    width: '100%',
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  appBar: {
    width: '75%',
    margin: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
}));

const paramsData = { mode: 'date' };

const bodyDataChamber = {
  startDate: moment()
    .subtract(30, 'day')
    .utcOffset(0, true)
    .format('YYYY-MM-DD'),
  endDate: moment().utcOffset(0, true).format('YYYY-MM-DD'),
  compareStartDate: '',
  compareEndDate: '',
  fromYear: '',
  toYear: '',
  states: [],
  legalForms: [],
  departments: [],
  dateRange: 0,
  incomeType: [],
  payment: [],
  member: '2',
  block: [],
};

const bodyDataIncomePlot = {
  startDate: moment().subtract(1, 'year').utcOffset(0, true),
  endDate: moment().utcOffset(0, true),
  compareStartDate: '',
  compareEndDate: '',
  fromYear: '',
  toYear: '',
  states: [],
  legalForms: [],
  departments: [],
  dateRange: 2,
  incomeType: [],
  payment: [],
  member: '2',
  block: [],
};

const bodyDataExpensesPlot = {
  group: [],
  fromMonth: moment().subtract(1, 'year').utcOffset(0, true).format('YYYY-MM'),
  toMonth: moment().utcOffset(0, true).format('YYYY-MM'),
  compareFromMonth: '',
  compareToMonth: '',
  fromYear: '',
  toYear: '',
  dateRange: 2,
};

const bodyDataGemi = {
  startDate: moment()
    .subtract(30, 'day')
    .utcOffset(0, true)
    .format('YYYY-MM-DD'),
  endDate: moment().utcOffset(0, true).format('YYYY-MM-DD'),
  compareStartDate: '',
  compareEndDate: '',
  fromYear: '',
  toYear: '',
  states: [],
  legalForms: [],
  departments: [],
  dateRange: 0,
  incomeType: [],
  type: 1,
};

const bodyDataExpenses = {
  group: [],
  fromMonth: moment().subtract(1, 'year').utcOffset(0, true).format('YYYY-MM'),
  toMonth: moment().utcOffset(0, true).format('YYYY-MM'),
  compareFromMonth: '',
  compareToMonth: '',
  fromYear: '',
  toYear: '',
  dateRange: 2,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ paddingTop: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const DashboardPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [bodyInsight, setBodyInsight] = useState();
  let { keycloakstate } = useContext(UserContext);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    if (
      (getUserType(keycloakstate) == 'admin' &&
        getUserPermissions(process.env.inc_exp) == 'admin') ||
      (getUserType(keycloakstate) == 'chamberUser' &&
        getUserPermissions(process.env.inc_exp) == 'featureRole') ||
      getUserType(keycloakstate) == 'admin'
    ) {
      setValue(0);
    } else {
      setValue(3);
    }
  }, []);

  return (
    <BodyHandling.Provider value={{ bodyInsight, setBodyInsight }}>
      <Container
        direction='column'
        id='container'
        maxWidth='xl'
        className={classes.container}
      >
        <Grid container id='grid-container' spacing={3}>
          <Grid id='earnings-subscriptions' className={classes.boxWrapper}>
            <Grid
              id='app-bar'
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {(getUserType(keycloakstate) == 'admin' &&
                getUserPermissions(process.env.inc_exp) == 'admin') ||
              (getUserType(keycloakstate) == 'chamberUser' &&
                getUserPermissions(process.env.inc_exp) == 'featureRole') ||
              (getUserType(keycloakstate) == 'admin' &&
                getUserPermissions(process.env.establishments) == 'admin') ||
              (getUserType(keycloakstate) == 'chamberUser' &&
                getUserPermissions(process.env.establishments) ==
                  'featureRole') ||
              (getUserType(keycloakstate) == 'admin' &&
                getUserPermissions(process.env.gemicases) == 'admin') ||
              (getUserType(keycloakstate) == 'chamberUser' &&
                getUserPermissions(process.env.gemicases) == 'featureRole') ? (
                <AppBar
                  position='static'
                  className={classes.appBar}
                  id='app-bar-component'
                >
                  <ThemeProvider theme={tabsTheme}>
                    <Tabs
                      id='tabs'
                      value={value}
                      onChange={handleChange}
                      indicatorColor='secondary'
                      textColor='secondary'
                      variant='scrollable'
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label='tabs dashboard'
                      TabIndicatorProps={{
                        style: { height: '4px' },
                      }}
                    >
                      {(getUserType(keycloakstate) == 'admin' &&
                        getUserPermissions(process.env.inc_exp) == 'admin') ||
                      (getUserType(keycloakstate) == 'chamberUser' &&
                        getUserPermissions(process.env.inc_exp) ==
                          'featureRole') ? (
                        <Tab
                          label='ΕΣΟΔΑ / ΕΞΟΔΑ'
                          {...a11yProps(0)}
                          style={{ width: '25%' }}
                        />
                      ) : (
                        <Tab
                          label='ΕΣΟΔΑ / ΕΞΟΔΑ'
                          {...a11yProps(0)}
                          hidden
                          style={{ width: '25%' }}
                        />
                      )}
                      {(getUserType(keycloakstate) == 'admin' &&
                        getUserPermissions(process.env.inc_exp) == 'admin') ||
                      (getUserType(keycloakstate) == 'chamberUser' &&
                        getUserPermissions(process.env.inc_exp) ==
                          'featureRole') ? (
                        <Tab
                          label='ΕΣΟΔΑ'
                          {...a11yProps(1)}
                          style={{ width: '25%' }}
                        />
                      ) : (
                        <Tab
                          label='ΕΣΟΔΑ'
                          {...a11yProps(1)}
                          hidden
                          style={{ width: '25%' }}
                        />
                      )}
                      {(getUserType(keycloakstate) == 'admin' &&
                        getUserPermissions(process.env.inc_exp) == 'admin') ||
                      (getUserType(keycloakstate) == 'chamberUser' &&
                        getUserPermissions(process.env.inc_exp) ==
                          'featureRole') ? (
                        <Tab
                          label='ΕΞΟΔΑ'
                          {...a11yProps(2)}
                          style={{ width: '25%' }}
                        />
                      ) : (
                        <Tab
                          label='ΕΞΟΔΑ'
                          {...a11yProps(2)}
                          hidden
                          style={{ width: '25%' }}
                        />
                      )}

                      {(getUserType(keycloakstate) == 'admin' &&
                        getUserPermissions(process.env.establishments) ==
                          'admin') ||
                      (getUserType(keycloakstate) == 'chamberUser' &&
                        getUserPermissions(process.env.establishments) ==
                          'featureRole') ||
                      (getUserType(keycloakstate) == 'admin' &&
                        getUserPermissions(process.env.gemicases) == 'admin') ||
                      (getUserType(keycloakstate) == 'chamberUser' &&
                        getUserPermissions(process.env.gemicases) ==
                          'featureRole') ? (
                        <Tab
                          label='ΑΛΛΑ'
                          {...a11yProps(3)}
                          style={{ width: '25%' }}
                        />
                      ) : (
                        <Tab
                          label='ΑΛΛΑ'
                          {...a11yProps(3)}
                          hidden
                          style={{ width: '25%' }}
                        />
                      )}
                    </Tabs>
                  </ThemeProvider>
                </AppBar>
              ) : (
                <NoPermissionPage />
              )}
            </Grid>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
              style={{
                width: '100%',
              }}
            >
              {(getUserType(keycloakstate) == 'admin' &&
                getUserPermissions(process.env.inc_exp) == 'admin') ||
              (getUserType(keycloakstate) == 'chamberUser' &&
                getUserPermissions(process.env.inc_exp) == 'featureRole') ? (
                <TabPanel
                  id='income-expenses'
                  value={value}
                  index={0}
                  dir={theme.direction}
                >
                  <Grid item className={classes.boxWrapper2}>
                    <Grid item id='box1' className={classes.gridContainer7}>
                      <EarningsWithoutComparison
                        className={classes.gridContainer7}
                      />
                    </Grid>
                    <Grid item id='box1' className={classes.gridContainer7}>
                      <ExpensesWithoutComparison
                        className={classes.gridContainer7}
                      />
                    </Grid>
                  </Grid>
                  <Grid item className={classes.boxWrapper2}>
                    <Grid item id='box1' className={classes.gridContainer8}>
                      <EarningsExpensesPlot
                        method='POST'
                        urlIncome='/income/all'
                        nameReqIncome='/income'
                        urlExpenses='/expenses/all'
                        nameReqExpenses='/expenses'
                        title='Έσοδα / Έξοδα'
                        description='το τελευταίο έτος'
                        bodyIncome={bodyDataIncomePlot}
                        bodyExpenses={bodyDataExpensesPlot}
                        params={paramsData}
                        className={classes.gridContainer8}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              ) : (
                <TabPanel
                  id='income-expenses'
                  value={value}
                  index={0}
                  dir={theme.direction}
                ></TabPanel>
              )}
              {(getUserType(keycloakstate) == 'admin' &&
                getUserPermissions(process.env.inc_exp) == 'admin') ||
              (getUserType(keycloakstate) == 'chamberUser' &&
                getUserPermissions(process.env.inc_exp) == 'featureRole') ? (
                <TabPanel
                  id='income'
                  value={value}
                  index={1}
                  dir={theme.direction}
                >
                  <Grid item className={classes.boxWrapper2}>
                    <Grid item id='box1' className={classes.gridContainer1}>
                      <Earnings className={classes.gridContainer1} />
                    </Grid>
                    <Grid item id='box2' className={classes.gridContainer2}>
                      <IncomePlot
                        method='POST'
                        url='/income/all'
                        title='Εισπράξεις Επιμελητηρίου '
                        description='τις τελευταίες 30 ημέρες'
                        nameReq='/byTypeChamb'
                        body={bodyDataChamber}
                        params={paramsData}
                        className={classes.gridContainer2}
                      />
                    </Grid>
                  </Grid>
                  <Grid item className={classes.boxWrapper2}>
                    <Grid item id='box3' className={classes.gridContainer3}>
                      <IncomePlot
                        method='POST'
                        url='/gemiIncome/all'
                        title='Ποσό Επιμελητηρίου Εισπράξεων Γ.Ε.Μη'
                        description='τις τελευταίες 30 ημέρες'
                        nameReq='/byTypeGemi'
                        body={bodyDataGemi}
                        params={paramsData}
                        className={classes.gridContainer3}
                      />
                    </Grid>
                    <Grid item id='box4' className={classes.gridContainer4}>
                      <IncomeGemi className={classes.gridContainer4} />
                    </Grid>
                  </Grid>
                </TabPanel>
              ) : (
                <TabPanel
                  id='income'
                  value={value}
                  index={1}
                  dir={theme.direction}
                ></TabPanel>
              )}

              {(getUserType(keycloakstate) == 'admin' &&
                getUserPermissions(process.env.inc_exp) == 'admin') ||
              (getUserType(keycloakstate) == 'chamberUser' &&
                getUserPermissions(process.env.inc_exp) == 'featureRole') ? (
                <TabPanel
                  id='expenses'
                  value={value}
                  index={2}
                  dir={theme.direction}
                >
                  <Grid item className={classes.boxWrapper2}>
                    <Grid item id='box5' className={classes.gridContainer7}>
                      <IncomePlot
                        method='POST'
                        url='/expenses/all'
                        title='Συνολικά Έξοδα'
                        description='το τελευταίο έτος'
                        nameReq='/byTypeExpenses'
                        body={bodyDataExpenses}
                        params={paramsData}
                        className={classes.gridContainer7}
                      />
                    </Grid>
                    <Grid item id='box1' className={classes.gridContainer7}>
                      <ExpensesBarChart className={classes.gridContainer7} />
                    </Grid>
                  </Grid>
                </TabPanel>
              ) : (
                <TabPanel
                  id='expenses'
                  value={value}
                  index={2}
                  dir={theme.direction}
                ></TabPanel>
              )}

              <TabPanel
                id='other'
                value={value}
                index={3}
                dir={theme.direction}
              >
                <Grid item className={classes.boxWrapper2}>
                  {(getUserType(keycloakstate) == 'admin' &&
                    getUserPermissions(process.env.establishments) ==
                      'admin') ||
                  (getUserType(keycloakstate) == 'chamberUser' &&
                    getUserPermissions(process.env.establishments) ==
                      'featureRole') ? (
                    <Grid item id='box5' className={classes.gridContainer6}>
                      <NewSubscriptions className={classes.gridContainer6} />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {(getUserType(keycloakstate) == 'admin' &&
                    getUserPermissions(process.env.gemicases) == 'admin') ||
                  (getUserType(keycloakstate) == 'chamberUser' &&
                    getUserPermissions(process.env.gemicases) ==
                      'featureRole') ? (
                    process.env.gemiCasesEnabled ? (
                      <Grid item id='box8' className={classes.gridContainer6}>
                        <BarChartDashboard
                          title='Υποθέσεις Γ.Ε.Μη ανά κατάσταση'
                          description='το τελευταίο έτος'
                          className={classes.gridContainer6}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </Grid>
              </TabPanel>
            </SwipeableViews>
          </Grid>
        </Grid>
      </Container>
    </BodyHandling.Provider>
  );
};
export default DashboardPage;
